@use "variables" as *;
@use "sass:color";

@font-face {
  font-family: "Horizon Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Horizon Regular"),
    url("./assets/fonts/Horizon-PersonalUse.woff") format("woff");
}

/* BEM methodology */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
}

body {
  overflow-x: hidden;
  color: white;
  background: linear-gradient(135deg, #1e293b, $dark-bg-color);
  line-height: 1.5;
}

a {
  color: $main-color;
  text-decoration: none;

  &:hover {
    color: color.adjust($main-color, $lightness: -10%);
  }
}

/*******************************
 *            Navbar           *
 *******************************/
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $dark-bg-color;
  color: $main-color;
  // margin-bottom: 1rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    max-width: 50px;
    height: auto;
  }

  h1 {
    font-family: "Horizon Regular";
    background: linear-gradient(
      180deg,
      $main-color,
      #e91e63,
      #aa1e9c,
      #8e24aa,
      #9c27b0
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/*******************************
 *           Sections          *
 *******************************/
section {
  margin: $section-margin 0;
}

.section {
  &-title {
    text-align: center;
    margin-bottom: $section-margin;

    h2 {
      color: $electric-violet;
      margin: 0;
    }
  }
}

/*******************************
 *           Header            *
 *******************************/
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
  overflow: hidden;

  .parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2000px;
    z-index: 0;
    opacity: 0.2;
    background-position: top;
    background-size: contain;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .header-container {
    max-width: 1200px;
    padding: 2rem;
  }

  @media (max-width: $breakpoint-xs) {
    .header-container {
      padding: 2rem 10px;
    }
  }
}

/*******************************
 *          Container          *
 *******************************/
.container {
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 2rem;
}

/*******************************
 *           Pillars           *
 *******************************/
.pillars {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px $electric-violet, 0 0 25px $electric-violet;
  padding: 2rem;
  z-index: 1;
  background-color: #0f172a8c;

  .cards-container {
    display: flex;
    /*For test only*/
    // border: 1px solid white;

    .cards-column {
      /*For test only*/
      // border: 2px solid blue;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .cards-grid {
      position: relative;
      display: grid;
      margin-bottom: $section-margin;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      // max-width: 100%;
      height: min-content;
      transition: all 0.5s ease;
      flex-grow: 1;
      /*For test only*/
      // border: 1px solid rgb(255, 0, 0);
    }
  }
}

.card {
  background: $card-bg-color;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #fff;
  box-shadow: 0 4px 15px #0000004d;
  transition: transform 0.3s, box-shadow 0.3s, border 0.3s;
  cursor: pointer;
  transform-style: preserve-3d;

  &:hover {
    transform: translateY(-5px);
    &::before {
      content: "";
      opacity: var(--before-opacity);
      position: absolute;
      inset: -5px;
      transform: translate3d(0px, 0px, -1px);
      background: linear-gradient(
        180deg,
        $main-color,
        #e91e63,
        #aa1e9c,
        #8e24aa,
        #9c27b0
      );
      filter: blur(10px);

      @media (max-width: $breakpoint-sm) {
        content: none;
        opacity: 0; // extra seguridad
      }
    }
  }

  .card-title {
    margin-bottom: 10px;
    color: $electric-violet;
  }

  .expanded-content-mobile {
    display: none;
    border-top: 1px solid $main-color;
    margin-top: 2rem;
    padding-top: 1rem;
  }
}

.card-active {
  border: 1px solid $main-color;
}

.expanded-content {
  background-color: $card-bg-color;
  border-radius: 10px;
  padding: 1rem;
  margin: 0 0 0 1rem;
  display: none;
  overflow: hidden;
}

/* Desactivar todos los efectos hover en dispositivos táctiles */
@media (hover: none), (pointer: coarse) {
  .card:hover {
    box-shadow: none !important;
  }
}

/*******************************
 *         Language Menu       *
 *******************************/
/* Contenedor del menú de idiomas */
.language-menu {
  position: relative;
  display: inline-block;

  /* Mejoras para accesibilidad */
  &:focus-within {
    outline: 2px solid $main-color;
    border-radius: 10px;
  }

  /* Botón que abre el menú */
  &__button {
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: $main-color;
    color: white;
    border-radius: 10px;
    font-weight: 500;
    transition: background-color 0.2s ease;

    /* Añadimos box-shadow para mejor apariencia */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    /* Mejoramos hover */
    &:hover,
    :focus,
    :focus-visible {
      background-color: $red-fuchsia;
      color: #333;
    }

    /* Mejoramos focus */
    // &:focus {
    //   outline: none;
    //   box-shadow: 0 0 0 2px $main-color;
    // }

    /* Flecha indicadora */
    span {
      margin-left: 8px;
      font-size: 12px;
      display: inline-block; /* Importante para la animación con GSAP */
    }
  }

  /* El menú desplegable - optimizado para GSAP */
  &__dropdown {
    position: absolute;
    top: calc(100% + 5px); /* Un poco más separado del botón */
    right: 0;
    background-color: $dark-bg;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 150px;
    overflow: hidden;
    z-index: 1000;
  }

  /* Items dentro del menú */
  &__item {
    padding: 12px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    color: white;
    transition: background-color 0.15s ease;

    /* Imagen de la bandera */
    img {
      max-height: 1rem;
      margin-right: 0.5rem;
      border-radius: 2px; /* Suavizamos los bordes de las banderas */
    }

    /* Mejoramos hover */
    &:hover {
      background-color: color.adjust($red-fuchsia, $lightness: -10%);
      color: white;
    }

    /* Mejoramos focus */
    &:focus {
      outline: none;
      background-color: color.adjust($main-color, $lightness: -10%);
      box-shadow: inset 0 0 0 2px $main-color;
    }
  }

  /* Estilo para el item activo */
  &__item--active {
    font-weight: bold;
    color: color.adjust($main-color, $lightness: 40%);
    background-color: color.adjust($main-color, $lightness: -10%);

    /* Indicador visual adicional */
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: $main-color;
    }
  }
}

/* Mejoramos la accesibilidad con focus visibles */
@media (prefers-reduced-motion: reduce) {
  .language-menu__button::after {
    transition: none;
  }

  .language-menu__item {
    transition: none;
  }
}

/*******************************
 *       Experience text       *
 *******************************/
.experience-text {
  position: relative;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  font-weight: 500;
  color: #d1d5db; /* Mantiene el texto en blanco */
  z-index: 1;
  box-shadow: 0px 0px 5px $main-color;
  background-color: #1e1e2fc7;
}

/*******************************
 *            Modals           *
 *******************************/
.modals {
  display: none;
}

.modal-content {
  img {
    width: 100%;
    opacity: 0;
  }
  .modal-content__text:nth-child(n) {
    opacity: 0;
    text-align: left;
  }
  .modal-content__text--title {
    margin: 2rem 0 1rem;
    color: $main-color;
    font-size: xx-large;
    text-align: center;
  }
  ul {
    padding-left: 2rem;

    li {
      margin: 0.5rem 0;
    }
  }
}

.modal-content__demos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;

  &--demo {
    h4 {
      margin-bottom: 1rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $main-color;
      padding-bottom: 1rem;
    }
  }
}

.demo-button {
  background-color: $main-color;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 1rem;
  display: block;
  text-align: center;
  max-width: max-content;

  &:hover {
    background-color: color.adjust($main-color, $lightness: -10%);
    color: white;
  }
}

/*******************************
 *         Conact Form         *
 *******************************/
.contact-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #contactForm {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 2rem;
  }
}

input,
textarea,
button,
select {
  padding: 0.5rem;
  border-radius: 10px;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 10px $main-color;
  }

  &.error {
    border: 2px solid #ad0d0d;
  }
}

button {
  background-color: $main-color;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: color.adjust($main-color, $lightness: -10%);
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
}

.whatsapp {
  display: flex;
  align-items: flex-end;
  .whatsapp-container {
    // overflow: hidden;
    margin-left: 0.5rem;
    position: relative;
    width: 48px;
    height: 48px;

    .whatsapp-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;
    }
  }
}

.form-alert {
  max-width: 500px;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem auto;

  &--success {
    display: none;
    background-color: #7de57461;
    border: 1px solid #0dad0d;
  }

  &--error {
    display: none;
    background-color: #ff868661;
    border: 1px solid #ad0d0d;
  }
}

/*******************************
 *            About            *
 *******************************/
.about-section {
  padding: 2rem 0;
  overflow: hidden;
}

.about-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 3rem;
}

.about-info {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.about-card {
  background-color: rgba(31, 41, 55, 0.8);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-card:hover {
  // transform: translateY(-5px);
  // box-shadow: 0 0 5px $main-color, 0 0 10px $main-color;
}

.about-card h3 {
  color: $main-color;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.about-map {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.map-container {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-info {
  display: flex;
  gap: 1rem;
}

.location-card {
  background-color: rgba(31, 41, 55, 0.8);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.location-card h4 {
  color: $main-color;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

/* Estilos para las estadísticas */
.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1.5rem;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: rgba(31, 41, 55, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-item {
  text-align: center;
  padding: 1rem;
  min-width: 150px;
}

.stat-item:hover {
  transform: scale(1.05);
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: $main-color;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1rem;
  color: white;
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .stats-container {
    gap: 1rem;
  }

  .stat-item {
    min-width: 120px;
  }
}

/*******************************
 *           Footer            *
 *******************************/
footer {
  background-color: $dark-bg-color;
  color: $main-color;
  padding: 1rem;
  text-align: center;
}
