/*******************************
 *             Font            *
 *******************************/
$font-family: "Roboto", sans-serif;

/*******************************
 *            Colors           *
 *******************************/
$main-color: #9c27b0;
$dark-bg-color: #1e1e2f;
$card-bg-color: #1f2937; // #1f2937cc

$red-fuchsia: #e91e63;
$red-pink: #d81b60;
$highlight-pink: #f063a4;
$soft-pink: #f8bbd0;
$violet-pink: #aa1e9c;
$medium-violet: #8e24aa;
$violet: #7b1fa2;
$dark-violet: #6a1e9a;
$electric-violet: #9c27b0;
$light-electric-violet: #bd4de6;

$dark-bg: #1e1e2f;
$secondary-bg: #27293d;
$container-bg: #32304c;
$card-bg: #3c3b5c;

$main-gradient: linear-gradient(135deg, $red-fuchsia, $electric-violet);
$bg-gradient: linear-gradient(135deg, $dark-bg, $card-bg);
$dark-gradient: linear-gradient(135deg, $main-color, $dark-violet);
$light-gradient: linear-gradient(
  135deg,
  $highlight-pink,
  $light-electric-violet
);

/*******************************
 *           Margins           *
 *******************************/
$section-margin: 40px;

/*******************************
 *           @media            *
 *******************************/
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

$media-xs: "(max-width: #{$breakpoint-xs})";
$media-sm: "(max-width: #{$breakpoint-sm})";
$media-md: "(max-width: #{$breakpoint-md})";
$media-lg: "(max-width: #{$breakpoint-lg})";
$media-xl: "(max-width: #{$breakpoint-xl})";